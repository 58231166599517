import React from 'react';
import { StoryblokComponent } from '@storyblok/react';

const IndexTemplate = ({ story }) => {
    return story.content.blocks.map(
        blok =>
            !blok?.disabled && (
                <div key={blok._uid} className="storyblok-section">
                    <StoryblokComponent blok={blok} />
                </div>
            )
    );
};

export default IndexTemplate;
