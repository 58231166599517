import React from 'react';
import PropTypes from 'prop-types';
import { getShopifyCollectionByHandle } from 'helpers/requests/shopify-data-requests';
import { gShopifyCollection } from 'helpers/graphql';

import IndexTemplate from 'components/templates/IndexTemplate';
import Layout from 'components/templates/Layout';
import { productionSiteUrl } from 'helpers/constants/general';
import { useStoryblokState } from '@storyblok/react';

const HomePage = ({ story }) => {
    const data = { story: useStoryblokState(story) };

    return data.story ? (
        <Layout metadata={data?.story?.content?.metadata} canonicalUrl={`${productionSiteUrl}`}>
            {/* <link
                rel="preload"
                as="image"
                href="https://res.cloudinary.com/conspire-agency-zia-tile/image/upload/dpr_3.0,f_auto/v1/bitmap_dn5fed.png"
            /> */}
            <IndexTemplate story={data.story} />
        </Layout>
    ) : null;
};

export async function getStaticProps(context) {
    // the slug of the story
    let fullSlug = 'home';
    // the storyblok params
    let options = {
        version: process.env.NEXT_PUBLIC_STORYBLOK_VERSION || 'published', // or 'published'
        // appends the cache version to get the latest content
        cv: Date.now(),
    };

    // checks if Next.js is in preview mode
    if (context.preview) {
        // loads the draft version
        options.version = 'draft';
    }

    // loads the story from the Storyblok API
    // const storyblokApi = getStoryblokApi();
    // let { data } = await storyblokApi.get(
    //     `cdn/stories/${fullSlug}?resolve_relations=FeaturedBlogPosts.articles`,
    //     options
    // );
    const response = await fetch(
        `https://api.storyblok.com/v2/cdn/stories/${fullSlug}?token=${process.env.NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN}&version=${options.version}&cv=${options.cv}&resolve_relations=FeaturedBlogPosts.articles`
    );
    const data = await response.json();

    for (let component of data.story.content.blocks) {
        if (component.component === 'FeaturedCollection' && component.collection) {
            const { data: featuredCollectionData } = await getShopifyCollectionByHandle({
                handle: component.collection,
                first: 3,
            });

            component.shopifyData = featuredCollectionData?.collection
                ? gShopifyCollection(featuredCollectionData)
                : {};
        }
        if (component.component === 'FeaturedCollectionsProducts') {
            if (component?.collections?.length > 0) {
                let allProducts = [];
                component.products = {};
                for (let [index, collection] of component.collections.entries()) {
                    const { data: collectionData } = await getShopifyCollectionByHandle({
                        handle: collection.handle,
                    });
                    if (collectionData?.collection?.products) {
                        component.collections[index].title = collectionData?.collection?.title;
                        const collectionProducts = gShopifyCollection(collectionData).products.map(
                            product => ({
                                ...product,
                                collection: {
                                    title: collectionData.collection.title,
                                    color:
                                        collectionData.collection?.metafields?.find(
                                            mf => mf?.key === 'color_theme'
                                        )?.value || null,
                                },
                            })
                        );

                        allProducts.push(
                            ...collectionProducts.filter(
                                cp => !allProducts.find(ap => cp.handle === ap.handle)
                            )
                        );
                        component.products[collection.handle] = collectionProducts.sort();
                    }
                }
                component.products.all = allProducts.sort((a, b) => {
                    const handleA = a.handle.toUpperCase(); // ignore upper and lowercase
                    const handleB = b.handle.toUpperCase(); // ignore upper and lowercase
                    if (handleA < handleB) {
                        return -1;
                    }
                    if (handleA > handleB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                });
            }
        }
    }

    // return the story from Storyblok
    return {
        props: {
            story: data?.story || null,
        },
        revalidate: 10,
    };
}

HomePage.defaultProps = {
    story: {},
};

HomePage.propTypes = {
    story: PropTypes.oneOfType([PropTypes.object]),
};

export default HomePage;
