import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import SiteContext from 'context/SiteContext';
import { logInfo } from 'helpers/logging';
import { APPS } from 'helpers/constants/general';
import {
    getCartSubtotal,
    transformLineItemsToElevarProducts,
    transformToElevarUserProperties,
} from 'helpers/elevar';

const Head = dynamic(() => import('components/atoms/Head'));

const Header = dynamic(() => import('components/organisms/Navigation/Header'));
const Footer = dynamic(() => import('components/organisms/Navigation/Footer'));
const SidebarMenu = dynamic(() => import('components/organisms/Navigation/SidebarMenu'));
const SidebarCart = dynamic(() => import('components/organisms/Navigation/SidebarCart'));
const NotificationBar = dynamic(() => import('components/organisms/General/NotificationBar'));

const Layout = ({ children = {}, metadata, page, canonicalUrl }) => {
    const {
        setAfterLoginUrl,
        store: {
            customer,
            customerLoggedIn,
            checkout: { currencyCode, lineItems, lineItemsSubtotalPrice },
            tradeDiscountValue,
        },
    } = useContext(SiteContext);

    const router = useRouter();

    // Prefetch homepage for faster redirect logged out user
    useEffect(() => {
        router.prefetch('/');
    }, []);

    // Redirect logged out user, set home page class on body
    useEffect(() => {
        if (
            !Cookies.get('ZIA_SESS') &&
            router?.asPath?.includes('/account') &&
            !router?.asPath?.includes('/activate')
        ) {
            // Set after login URL so what we can redirect after user log in
            setAfterLoginUrl(router.asPath, true);
            router.push('/');
        }
    }, [router.asPath]);

    useEffect(() => {
        /* Wait for checkout to be initialized */
        if (!currencyCode || (customerLoggedIn && !customer)) {
            return;
        }
        /** Elevar Base Data Layer **/
        window.ElevarDataLayer = window.ElevarDataLayer ?? [];
        const cartSubtotal = getCartSubtotal({
            lineItems,
            lineItemsSubtotalPrice,
            tradeDiscountValue,
        });

        const baseData = {
            event: 'dl_user_data',
            cart_total: cartSubtotal,
            user_properties: {
                ...transformToElevarUserProperties({ customer, customerLoggedIn }),
            },
            ecommerce: {
                currencyCode: currencyCode,
                cart_contents: {
                    products: transformLineItemsToElevarProducts({ lineItems: lineItems || [] }), // See the products array below
                },
            },
        };
        logInfo('Fire Elevar dl_user_data', APPS.FRONTEND, baseData);
        window.ElevarDataLayer.push(baseData);
    }, [currencyCode]);

    return (
        <>
            <Head metadata={metadata} page={page} canonicalUrl={canonicalUrl} />
            <Header />
            <main className="sections">{children}</main>
            <Footer />
            <NotificationBar />
            <SidebarMenu />
            <SidebarCart />
            <div id="conspire-wishlist-modal" />
        </>
    );
};

Layout.defaultProps = {
    metadata: {},
};

Layout.propTypes = {
    metadata: PropTypes.oneOfType([PropTypes.object]),
    page: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    canonicalUrl: PropTypes.string,
};

export default Layout;
